@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400;700&display=swap');

Body {
    font-family: 'Pixelify Sans', sans-serif;
}

.img-folder-s {
    width: 1.2em;
}

.img-folder-m {
    width: 2.2em;
}

.img-folder-l {
    width: 4.2em;
}

.img-folder-xl {
    width: 8.2em;
}


.folder-name {
    width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.folder-name-overflow {
    width: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.folder-time-align-end {
    text-align: right;
}

.file-name {
    width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-name-overflow {
    width: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.rotate-90 {
    transform: rotate(90deg);
}